<template>
  <d-confirm-dialog
      :title="title"
      :value="dialog"
      :status="status"
      @confirm="onClickRestart"
      @cancel="closeDialog"
  />
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

const props = {
  sensorType: {
    type: String,
    default: 'radar'
  },
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'RestartSensorDialog',
  props,
  computed: {
    ...mapGetters('sensors', ['status', 'error']),
    ...mapState('sites', ['activeSiteId']),
    title() {
      return `Restart All ${(this.capitalizeFirstLetter(this.sensorType))} ?`
    }
  },
  methods: {
    ...mapActions('sensors', {restartSensor: 'RESTART_SENSOR'}),
    ...mapMutations('sensors', {setError: 'SET_ERROR', setStatus: 'SET_STATUS'}),
    async onClickRestart() {
      const {sensorType} = this
      await this.restartSensor({sensor_type: sensorType, site_id: this.activeSiteId})
      this.$emit('onRestart')
      return this.closeDialog()
    },
    closeDialog() {
      return this.$emit('onClose')
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  watch: {
    dialog() {
      this.setError(null)
      this.setStatus(null)
    }
  }
}
</script>

<style scoped>
.v-btn {
  min-width: 0;
}
</style>
